.closeIconStyle {
    border-radius: 50%;
    cursor: pointer;
    background: #ECECEC;
    width: 24px;
    height: 24px;
    text-align: center;
}

.labelStyle {
    font-size: 12px;
    margin-bottom: 4px;
    margin-top: 8px;
    font-weight: 400;
    line-height: 15px;
    color: #656364;
}

.inputDetailsDivInventory {
    color: #333333;
    font-size: 12px;
    border: #D9D9D9 0.75px solid;
    padding: 5px;
    width: 345px;
    height: 36px;
    border-radius: 5.03px;
    display: flex;
    align-items: center;
    &.disabled {
        background-color: #f0f0f0;
        color: #666;
        cursor: not-allowed;
        user-select: none;
      }
}

.selectStyle {
    color: #333333;
    font-size: 12px;
    border: #D9D9D9 0.75px solid;
    padding: 5px;
    width: 345px;
    height: 36px;
    border-radius: 5.03px;
    margin-bottom: 20px;
}

.variationCard {    
    background: #F2F6FF;
    border: Mixed solid #D9D9D9;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 14px;
    width: 349px;
    height: 290px;
    margin-bottom: 10px;
}

.variationCardHeader {
    display: flex;
    justify-content: space-between;
}

.deleteIconStyle {
    cursor: pointer;
}

.variationCardTitle {
    font-size: 12px;
    margin-bottom: 4px;
    font-weight: 400;
    line-height: 15px;
    color: #333333;
    margin-bottom: 12px;
}

.variationSelect {
    color: #757575;
    font-size: 12px;
    border: #D9D9D9 0.75px solid;
    padding: 5px;
    width: 321px;
    height: 36px;
    border-radius: 5.03px;
    margin-bottom: 12px;
}

.variationInputDetail {
    color: #333333;
    font-size: 12px;
    border: #D9D9D9 0.75px solid;
    padding: 5px;
    width: 321px;
    height: 36px;
    border-radius: 5.03px;
    margin-bottom: 12px;
    background: white;
}

.checkboxContainer {
    margin-top: -8px;
    margin-left: -8px;
}

.manualCheckox {
    margin-right: 10px;
    color: #333333
}

.addAnotherVarDiv {
    text-align: end;
    margin-right: 20px;
    margin-bottom: 60px;
}

.addAnotherVar {
    font-size: 12px;
    color: #243B86;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
}

td{
    text-align: center;
}

// ---------------Edit Medicine ---------------

.skuDiv {
    width: 345px;
    height: 35px;
    background: #F9FAFE;
    border-radius: 8px;
    color: #333333;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 18px;
}

.searchInfoTitle {
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;
}

.searchInfoSubtext {
    font-size: 12px;    
    color: #656364;    
    font-weight: 400;    
}